<template>
  <div>
    <Header nav="profile" active="password" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Change Password</h5>
            </div>
          </div>

          <div class="widget-list">
            <form class="form-horizontal" @submit.prevent="send">

              <div class="row">
                <div class="col-md-3 pr-3 d-none d-md-block">
                  <p>Changing your password takes effect immediately. You will need to log in with your new password after changing it.</p>
                </div>
                <div class="col-md-9">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Current Password</label>
                          <div class="col-md-9">
                            <input type="password" v-model="password.current" class="form-control" maxlength="254" autocomplete="new-password" required />
                            <div class="text-muted pt-1">You must provide your current password in order to change it.</div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">New Password</label>
                          <div class="col-md-9">
                            <input type="password" v-model="password.new" class="form-control" maxlength="254" autocomplete="new-password" required />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Confirm Password</label>
                          <div class="col-md-9">
                            <input type="password" v-model="password.confirm" class="form-control" maxlength="254" autocomplete="new-password" required />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <button type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-check mr-2"></i>Save Changes</button>
                </div>
              </div>

            </form>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      password: {
        current: '',
        new: '',
        confirm: '',
      },
    };
  },
  methods: {
    send() {
      if (this.password.new !== this.password.confirm) {
        toast.danger(this, 'Passwords do not match');
        return;
      }
      this.$api.patch('/profile/password/update', this.password)
        .then(() => {
          this.$auth.clearProfile();
          toast.icon(this, 'success', 'fa-check');
          setTimeout(() => { this.$router.push({ path: '/login', query: { redirect: '/profile' } }); }, 1250);
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  components: {
    Header,
  },
};
</script>
